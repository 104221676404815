.topwrapper {
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    margin-top: -30px;
}
.title {
    color: var(--humber-primary);
    font-size: 176%;
}
.description {
    color: var(--humber-black);
    font-size: 17px;
    padding: 10px 0;
    line-height: 1;
}
.catstory {
    text-align: center;
    background: var(--humber-dark);
    opacity: 1;
    width: 100%;
    padding: 20px 100px 50px 100px;
    margin: 0 auto 30px auto;
box-shadow: 0px 0px 85px rgba(0, 0, 0, 0.05);
border-radius: 10px;


}
@media (max-width: 767px) {
    .catstory {
        margin-top: 30px;
        width: 100%;
        padding: 20px 0;
    }
}
.readme {
 
    
    padding: 30px 20px;
    line-height: 1.4;
    font-size: 1rem;
}
.btn {
    /* background-color: #2B9FDD; */
    background: var(--humber-button-color);
    border: none;
    outline: none;
    padding: 10px 50px;
    cursor: pointer;
    color: var(--humber-dark);
    border:1px solid var(--border-color-light);
    font-size: 16px;
    text-transform: capitalize;
    font-weight: bold;
    border-radius: 30px;
}


.image_cat{
    image-rendering: pixelated;
    width: 78px;
    vertical-align: middle;
    border-style: none;
}

@media (max-width:600px){
    .image_cat{
        image-rendering: pixelated;
        width: 78px;
        vertical-align: middle;
        border-style: none;
        margin-top: 1rem;
    }
}

.trivia_timer{
    height: 56px;
    margin-top: 20px;
    image-rendering: pixelated;
}

.tokenAdded{
    text-align: center;
    
    margin-top: 0px;
    margin-bottom: -15px;
    
  font-weight: 800;
font-size: 1.3rem;
line-height: 31px;
/* or 107% */



color: #123A74;
}

.text{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 1rem;
line-height: 25px;
/* or 156% */

text-align: center;

color: #1B1E1D;
  }

  .topupsuccess{
    background: #F46800;
    border: none;
    padding: .7rem;
    color: white;
    font-size: 1rem;
    width: 100%;
    max-width: 170px;
    
    border-radius:170px;
  }



  .categorywrapper{
    width: 100%; 
    margin: 10px auto;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -moz-column-gap: 5%;
    grid-column-gap: 5%;
    -webkit-column-gap: 5%;
    column-gap: 5%;
    grid-row-gap: 20px;
    row-gap: 20px;

  }

 .categorycard {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    justify-content: center;
    width: 30%;
    height: 200px;
    background: var(--humber-light);
    -webkit-box-shadow: 0px 0px 85px rgb(0 0 0 / 5%);
    -moz-box-shadow: 0px 0px 85px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 85px rgb(0 0 0 / 5%);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 10px rgb(0 0 0 / 15%);
    -moz-box-shadow: 0px 0px 10px rgb(0 0 0 / 15%);
    box-shadow: 0px 0px 10px rgb(0 0 0 / 15%);
}

.card_title{
    color:#133873;
font-family: Poppins;
font-weight: 600;
font-size: 20px;

}

.card_description{
    font-size: 14px;
    
    color:#133873;
    word-wrap: break-word;
    /* padding-left: 10px;
    padding-right: 10px; */
    width: 59%;
}
  

@media (max-width: 767px){
.categorycard {
    max-width: 80%;
    min-width: 100%;
    
}

.categorywrapper{
    width: 80%;
}
}